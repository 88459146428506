anime.timeline({loop: false})
  .add({
    targets: '.top-slider-hero-inner .slider-inner-text',
    scale: [14,1],
    opacity: [0,1],
    easing: "easeOutCirc",
    duration: 2000,
    delay: anime.stagger(800, {start: 1200})
  });


/*
anime.timeline({loop: true})
  .add({
    targets: '.local-heading-sub-wrapper .local-icon-sub',
    opacity: [0,1],
    easing: "easeOutCirc",
    duration: 900
  })
  .add({
    targets: '.local-heading-sub-wrapper .local-headline',
    opacity: [0,1],
    easing: "easeOutCirc",
    duration: 900
  })
  .add({
    targets: '.local-heading-sub-wrapper .local-headline-border',
    scaleX: [0,1],
    opacity: [0.5,1],
    easing: "easeInOutExpo",
    duration: 900
  });
*/



// スクロールに応じてアニメーション
const trigger = new ScrollTrigger.default();
trigger.add('[data-trigger="local-heading"]', {
  once: true,
  offset: {
    viewport: {
      y: (trigger, frame, direction) => {
        return trigger.visible ? 0 : .1
      }
    }
  },
  toggle: {
    class: {
        in: 'visible',
        out: 'invisible'
    },
    callback: {
      in: (trigger) => {

        // アイコン
        anime({
          targets: '.invisible .local-icon-sub',
          translateX: [-150, 0],
          opacity: [0,1],
          easing: "easeInOutElastic",
          duration: 2000,
          delay: 0
        });

        // テキスト
        anime({
          targets: '.invisible .local-headline',
          translateX: [150, 0],
          opacity: [0,1],
          easing: "easeOutCirc",
          duration: 2000,
          delay: 0
        });

        // border
        anime({
          targets: '.invisible .local-headline-border',
          scaleX: [0,1],
          opacity: [0,1],
          easing: "easeOutCirc",
          duration: 2000,
          delay: 0
        });

      }
    }
  }
});
